import gql from "graphql-tag";

export const ITEMS = gql`
  query Items(
    $where: ItemWhereInput
    $isIn: [ItemWhereInput]
    $like: ItemWhereInput
    $skip: Int
    $take: Int
    $isNotIn: [ItemWhereInput]
  ) {
    items(
      where: $where
      isIn: $isIn
      like: $like
      skip: $skip
      take: $take
      isNotIn: $isNotIn
    ) {
      data {
        id
        code
        name
        catalogCode
        manufacturerCode
        oem
        qryGroup19
        topVente
        nouveau
        dateArrivage
        application1
        application2
        application3
        photo
        photoVersion
        photo2
        photoVersion2
        prices {
          id
          value
          updatedDate
        }
        thumbnail
        discount {
          id
          discountLines {
            id
            min
            max
            startDate
            endDate
            value
            account {
              title
              id
            }
          }
        }
        itemWareHouses {
          id
          quantity
          wareHouse {
            id
            name
            code
            city
          }
        }
      }
      count
    }
  }
`;

export const ITEM = gql`
  query Item($where: ItemWhereInput) {
    item(where: $where) {
      id
      code
      name
      catalogCode
      manufacturerCode
      oem
      qryGroup19
      topVente
      dateArrivage
      application1
      application2
      application3
      nouveau
      photo
      photoVersion
      photo2
      photoVersion2
      prices {
        id
        value
      }
      discount {
        id
        discountLines {
          id
          min
          max
          startDate
          endDate
          value
          account {
            title
            id
          }
        }
      }
      prices {
        id
        value
        updatedDate
      }
      thumbnail
      itemWareHouses {
        id
        quantity
        wareHouse {
          id
          name
          code
          city
        }
      }
    }
  }
`;

export const ITEM_ONLY_STOCK = gql`
  query ItemOnlyStock($where: ItemWhereInput) {
    itemOnlyStock(where: $where) {
      id
      itemWareHouses {
        id
        quantity
        wareHouse {
          id
          name
          code
          city
        }
      }
    }
  }
`;

export const ITEMS_ADMIN = gql`
  query itemsAdmin($itemsAdminInput: ItemsAdminInput) {
    itemsAdmin(itemsAdminInput: $itemsAdminInput) {
      data {
        id
        code
        name
        catalogCode
        manufacturerCode
        oem
        qryGroup19
        topVente
        nouveau
        dateArrivage
        application1
        application2
        application3
        photo
        photoVersion
        photo2
        photoVersion2
        vedette
        prices {
          id
          value
          updatedDate
        }
        thumbnail
        discount {
          id
          discountLines {
            id
            min
            max
            startDate
            endDate
            value
            account {
              title
              id
            }
          }
        }
        itemWareHouses {
          id
          quantity
          wareHouse {
            id
            name
            code
            city
          }
        }
      }
      count
    }
  }
`;

export default {
  ITEMS,
  ITEM,
  ITEM,
  ITEMS_ADMIN,
};
