import { Col, Icon, Input, Select } from "antd";
import cuid from "cuid";
import React, { useMemo } from "react";
import { useHistory } from "react-router-dom";
import ItemEntity from "../../../../../entities/item";
import ArticleNotManaged from "../../../../../shared/assets/images/articleNotManaged.svg";
import Overpriced from "../../../../../shared/assets/images/overpriced.svg";
import SoldOut from "../../../../../shared/assets/images/soldOut.svg";
import Favorite from "../../../../../shared/assets/images/wishlist.svg";
import Icons from "../../../../../shared/components/Icon";
import Image from "../../../../../shared/components/Image";
import Loader from "../../../../../shared/components/Loader";

import Table from "../../../../../shared/components/Table";
import {
  IMAGELINK,
  ITEM_WAREHOUSE_COLOR,
  ITEM_WAREHOUSE_DESCRIPTION,
  ITEM_WAREHOUSE_DESCRIPTION_VIEW,
} from "../../../../../utils/constants";
import Price from "../../../Catalog/Articles/List/Widgets/Card/Price";
import AddToCart from "./AddToCart";
import Quantity from "./Quantity";

import { Dropdown } from "antd";
import { isEmpty } from "lodash";
import { useContext } from "react";
import { StoreContext } from "../../../../../shared/store";
import { numberFormatter } from "../../../../../utils/formatter-functions";
import { getItemWareHousesAvailibity } from "../../../../../utils/helpers";
import Description from "./Description";
import GivedPrice from "./GivedPrice";

const { Option } = Select;

const options = [
  {
    value: ITEM_WAREHOUSE_DESCRIPTION.FAVORITE,
    title: () => <img src={Favorite} alt={"Favorite"} />,
  },
  {
    value: ITEM_WAREHOUSE_DESCRIPTION.NOT_HANDLED,
    title: () => <img src={ArticleNotManaged} alt={"articleNotManaged"} />,
  },
  {
    value: ITEM_WAREHOUSE_DESCRIPTION.NOT_AVAILAIBLE,
    title: () => <img src={SoldOut} alt={"soldOut"} />,
  },
  {
    value: ITEM_WAREHOUSE_DESCRIPTION.TOO_EXPENSIVE,
    title: () => <img src={Overpriced} alt={"overpriced"} />,
  },
];

export const ItemWareHousesList = ({ item, itemWareHouses: iwh, quantity }) => {
  console.log("item ItemWareHousesList:", item);

  const itemWareHouses = iwh || [];
  const { user = {} } = useContext(StoreContext);

  const Menu = ({ wareHouses }) => (
    <div
      className="div-horizontal"
      style={{
        flexWrap: "wrap",
        background: "white",
        maxWidth: "520px",
        overflowY: "auto",
        maxHeight: "500px",
      }}
    >
      {wareHouses.map(
        ({ wareHouse: { city }, quantity: wareHouseQuantity }, index) => (
          <div
            className="div-vertical stock_par_magasin"
            style={{
              width:
                index < wareHouses.length - 1 || wareHouses.length % 2 == 0
                  ? "50%"
                  : "100%",
            }}
          >
            <div className="div-horizontal center">
              <span
                className={
                  quantity == 0
                    ? `ant-dropdown-link warehouse--dropdown warehouse--inactif`
                    : `ant-dropdown-link warehouse--dropdown warehouse--actif`
                }
                onClick={(e) => e.preventDefault()}
              ></span>

              <label className="ville">{city}</label>
            </div>
            <label className="quantite">
              {user.role.name === "admin" ||
              user.role.name === "commercial" ||
              user.role.name === "commercial régional"
                ? "Quantité disponible : " +
                  numberFormatter(wareHouseQuantity, 0) +
                  " unité" +
                  (wareHouseQuantity > 1 ? "s" : "")
                : ""}
            </label>

            <label
              className={
                "disponibilite " +
                (wareHouseQuantity > 0 ? "disponible" : "indisponible")
              }
            >
              {wareHouseQuantity > 0 ? "Disponible" : "Indisponible"}
            </label>
          </div>
        )
      )}
    </div>
  );

  const wareHouses = useMemo(
    () => itemWareHouses.filter((item) => item.quantity > 0),
    [itemWareHouses]
  );

  if (isEmpty(wareHouses)) {
    return (
      <div
        className="item--wareHouses"
        style={{
          backgroundColor:
            ITEM_WAREHOUSE_COLOR[
              getItemWareHousesAvailibity(
                itemWareHouses,
                quantity,
                item.id === item.code
              )
            ],
        }}
      />
    );
  }

  return (
    <Dropdown overlay={Menu({ wareHouses })} placement="bottomRight">
      <div
        className="item--wareHouses"
        style={{
          backgroundColor:
            ITEM_WAREHOUSE_COLOR[
              getItemWareHousesAvailibity(
                itemWareHouses,
                quantity,
                item.id === item.code
              )
            ],
        }}
      />
    </Dropdown>
  );
};

const columns = ({ filter, setFilter, actions, account = {} }) => [
  {
    title: () => {
      return (
        <Col>
          <Input
            placeholder="Numero Article"
            value={filter.item}
            onChange={(_) => setFilter({ ...filter, item: _.target.value })}
          />
        </Col>
      );
    },
    render: (record) => <span>{record.item.id}</span>,
    align: "center",
  },
  {
    title: "Image",
    key: "image",
    render: (record) => {
      const { image } = record;
      return (
        <Image
          image={`${IMAGELINK.imageURL50}${image}`}
          style={{ width: 40, height: 40, borderRadius: 50 }}
        ></Image>
      );
    },
    align: "center",
  },
  {
    title: () => {
      return (
        <Col>
          <Input
            placeholder="Votre prix"
            value={filter.price}
            onChange={(_) => setFilter({ ...filter, price: _.target.value })}
          />
        </Col>
      );
    },
    key: "price",
    align: "right",
    render: (record) => <GivedPrice record={record} />,
  },
  {
    title: (
      <span style={{ textAlign: "center", color: "black" }}>
        Prix unitaire TTC
      </span>
    ),
    key: "priceTTC",
    render: (record) => {
      const {
        quantity,
        item: { prices = [], discount = [] },
      } = record;

      return (
        <div>
          <Price
            quantity={quantity}
            prices={prices}
            discount={discount}
            account={account}
          />
        </div>
      );
    },
    align: "center",
  },
  {
    title: () => {
      return (
        <Col>
          <Input
            placeholder="Quantité"
            min={1}
            value={filter.quantity}
            onChange={(_) =>
              setFilter({ ...filter, description: _.target.value })
            }
          />
        </Col>
      );
    },
    key: "quantity",
    render: (record) => <Quantity record={record} />,
    align: "center",
  },
  {
    title: () => {
      return (
        <Select
          allowClear
          placeholder="Catégorie"
          className="text-center"
          defaultValue={filter.description}
          style={{ width: 120 }}
          onChange={(_) => setFilter({ ...filter, description: _ })}
        >
          {options.map((option) => (
            <Option key={cuid()} value={option.value}>
              {option.title()}
            </Option>
          ))}
        </Select>
      );
    },
    sorter: (a, b) => {
      const itemPositions = {};
      for (const [index, description] of Object.keys(Icons).entries()) {
        itemPositions[description] = index;
      }
      return itemPositions[a.description] - itemPositions[b.description];
    },
    sortDirections: ["descend", "ascend"],
    key: "categorie",
    render: (record) => <Description options={options} record={record} />,
    align: "center",
  },
  {
    key: "description",
    title: () => {
      return (
        <Col>
          <Input
            value={ITEM_WAREHOUSE_DESCRIPTION_VIEW[filter.description]}
            placeholder="Description"
            onChange={(_) =>
              setFilter({ ...filter, description: _.target.value })
            }
          />
        </Col>
      );
    },
    render: (record) => {
      const { description } = record;
      return <span> {ITEM_WAREHOUSE_DESCRIPTION_VIEW[description]}</span>;
    },
    align: "center",
  },
  {
    title: (
      <span style={{ textAlign: "center", color: "black" }}>Disponibilité</span>
    ),
    key: "availibility",
    width: 100,

    render: (record) => {
      const {
        item: { id },
        quantity,
      } = record;
      return (
        <div className="div-horizontal center center-justified">
          <ItemEntity
            filter={{ where: { id } }}
            NoData={() => <span>--</span>}
            View={Loader(({ data: { itemWareHouses = [] } }) => {
              return (
                <ItemWareHousesList
                  item={record.item}
                  itemWareHouses={itemWareHouses}
                  quantity={quantity}
                />
              );
            })}
          ></ItemEntity>
        </div>
      );
    },
    align: "center",
  },
  {
    title: <span style={{ textAlign: "center", color: "black" }}>Panier</span>,
    key: "addToCart",
    render: (record) => (
      <div className="div-horizontal center center-justified">
        <AddToCart
          itemId={record.item.id}
          itemName={record.item.name}
          url={record.image}
          quantity={record.quantity}
        ></AddToCart>
      </div>
    ),
    align: "center",
  },

  {
    title: (
      <span style={{ textAlign: "center", color: "black" }}>Supprimer</span>
    ),
    key: "delete",
    render: (record) => (
      <Icon
        className="iconTable2"
        onClick={() => actions.remove(record)}
        type="delete"
      />
    ),
    align: "center",
  },
];

const View = ({ filter, setFilter, account, ...rest }) => {
  const history = useHistory();
  return (
    <Table
      bordered
      size={"medium"}
      columns={columns({
        account,
        filter,
        setFilter,
        actions: {
          remove: (record) => {
            history.push(`/wish-list-items/delete/${record.id}`);
          },
        },
      })}
      {...rest}
    />
  );
};

export default View;
